import "../assets/css/CustomDualListbox.css";
import React, { useState } from "react";

const CustomDualListBox = ({ options, selected, onChange }) => {
  const [availableOptions, setAvailableOptions] = useState(
    options.filter((opt) => !selected.includes(opt.value))
  );
  const [selectedOptions, setSelectedOptions] = useState(
    options.filter((opt) => selected.includes(opt.value))
  );

  const [searchAvailable, setSearchAvailable] = useState("");
  const [searchSelected, setSearchSelected] = useState("");

  // State to track selected items
  const [selectedAvailableItems, setSelectedAvailableItems] = useState([]);
  const [selectedSelectedItems, setSelectedSelectedItems] = useState([]);

  const filteredAvailableOptions = availableOptions.filter((opt) =>
    opt.label.toLowerCase().includes(searchAvailable.toLowerCase())
  );
  const filteredSelectedOptions = selectedOptions.filter((opt) =>
    opt.label.toLowerCase().includes(searchSelected.toLowerCase())
  );

   // Handle item selection in Available List
  const handleSelectAvailable = (event, value) => {
    if (event.ctrlKey || event.metaKey) {
      // Multi-select with Ctrl
      setSelectedAvailableItems((prev) =>
        prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
      );
    } else {
      // Single select (Remove all other selections)
      setSelectedAvailableItems([value]);
    }
  };

 // Handle item selection in Selected List
  const handleSelectSelected = (event, value) => {
    if (event.ctrlKey || event.metaKey) {
      // Multi-select with Ctrl
      setSelectedSelectedItems((prev) =>
        prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
      );
    } else {
      // Single select (Remove all other selections)
      setSelectedSelectedItems([value]);
    }
  };

  // Move selected items to the "selected" list
  const moveToSelected = () => {
    const movingItems = availableOptions.filter((opt) =>
      selectedAvailableItems.includes(opt.value)
    );

    setAvailableOptions(
      availableOptions.filter((opt) => !selectedAvailableItems.includes(opt.value))
    );
    setSelectedOptions([...selectedOptions, ...movingItems]);

    // Update parent state
    onChange([...selected, ...movingItems.map((opt) => opt.value)]);

    setSelectedAvailableItems([]); // Reset selection
  };

  // Move selected items back to "available" list
  const moveToAvailable = () => {
    const movingItems = selectedOptions.filter((opt) =>
      selectedSelectedItems.includes(opt.value)
    );

    setSelectedOptions(
      selectedOptions.filter((opt) => !selectedSelectedItems.includes(opt.value))
    );
    setAvailableOptions([...availableOptions, ...movingItems]);

    // Update parent state
    onChange(selected.filter((val) => !selectedSelectedItems.includes(val)));

    setSelectedSelectedItems([]); // Reset selection
  };

  // Move all available options to selected
  const moveAllToSelected = () => {
    setSelectedOptions([...selectedOptions, ...availableOptions]);
    setAvailableOptions([]);
    onChange([...selected, ...availableOptions.map((opt) => opt.value)]);
    setSelectedAvailableItems([]); // Reset selection
  };

  // Move all selected options back to available
  const moveAllToAvailable = () => {
    setAvailableOptions([...availableOptions, ...selectedOptions]);
    setSelectedOptions([]);
    onChange([]);
    setSelectedSelectedItems([]); // Reset selection
  };

  // Handle double-click on Available List (Move to Selected)
  const handleDoubleClickAvailable = (value) => {
    const movingItem = availableOptions.find((opt) => opt.value === value);
    if (movingItem) {
      setAvailableOptions(availableOptions.filter((opt) => opt.value !== value));
      setSelectedOptions([...selectedOptions, movingItem]);

      onChange([...selected, movingItem.value]);
    }
  };

  // Handle double-click on Selected List (Move back to Available)
  const handleDoubleClickSelected = (value) => {
    const movingItem = selectedOptions.find((opt) => opt.value === value);
    if (movingItem) {
      setSelectedOptions(selectedOptions.filter((opt) => opt.value !== value));
      setAvailableOptions([...availableOptions, movingItem]);

      onChange(selected.filter((val) => val !== value));
    }
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
      ></link>
      <div className="dual-list-container">
        {/* Available List */}
        <div className="list">
          <input
            type="text"
            placeholder="Search..."
            value={searchAvailable}
            onChange={(e) => setSearchAvailable(e.target.value)}
            className="search-input"
          />
          <div className="scrollable-list">
            {filteredAvailableOptions.length > 0 ? (
              filteredAvailableOptions.map((option) => (
                <div
                  key={option.value}
                  className={`list-item ${
                    selectedAvailableItems.includes(option.value) ? "selected" : ""
                  }`}
                  onClick={(e) => handleSelectAvailable(e, option.value)}
                  onDoubleClick={() => handleDoubleClickAvailable(option.value)}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="empty-message"></div>
            )}
          </div>
        </div>

        {/* Button Group */}
        <div className="button-group">
          <button onClick={moveAllToSelected} disabled={availableOptions.length === 0}>
            <i className="fa-solid fa-angles-down"></i>
          </button>
          <button onClick={moveToSelected} disabled={selectedAvailableItems.length === 0}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
          <button onClick={moveToAvailable} disabled={selectedSelectedItems.length === 0}>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
          <button onClick={moveAllToAvailable} disabled={selectedOptions.length === 0}>
            <i className="fa-solid fa-angles-up"></i>
          </button>
        </div>

        {/* Selected List */}
        <div className="list">
          <input
            type="text"
            placeholder="Search..."
            value={searchSelected}
            onChange={(e) => setSearchSelected(e.target.value)}
            className="search-input"
          />
          <div className="scrollable-list">
            {filteredSelectedOptions.length > 0 ? (
              filteredSelectedOptions.map((option) => (
                <div
                  key={option.value}
                  className={`list-item ${
                    selectedSelectedItems.includes(option.value) ? "selected" : ""
                  }`}
                  onClick={(e) => handleSelectSelected(e, option.value)}
                  onDoubleClick={() => handleDoubleClickSelected(option.value)}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="empty-message"></div>
            )}
          </div>
        </div>
      </div>
      
    </>
  );
};

export default CustomDualListBox;
