import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../assets/css/Login.css"
import { toFollowEst, toggleLoginScreen } from "../actions/AccountAction"
import Icon from "@identitybuilding/idb-react-iconlib";
import { OsnInputDate, OsnInputText, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
import axios from 'axios';
import CustomDualListBox from "../components/CustomDualListbox";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { not } from "ajv/dist/compile/codegen";
import Select, { components } from "react-select";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';


// function useForceUpdate() {  
//     const [value, setValue] = useState(0); // integer state
//     return () => setValue(value => value + 1); // update state to force render
//     // An function that increment 👆🏻 the previous state like here 
//     // is better than directly setting `value + 1`
// }

const Login = (props) => {
    // const forceUpdate = useForceUpdate();
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        swipeToSlide: true,
    };
    const history = useHistory();
    const [notFromBelgium, setNotFromBelgium] = useState(false);
    const [municipality, setMunicipality] = useState(null);
    const [provinces, setProvinces] = useState([]);

    const [municipalityFollowing, setMunicipalityFollowing] = useState([]);
    const [provinceFollowing, setProvinceFollowing] = useState([]);

    const [municipalities, setMunicipalities] = useState([]);
    const [country, setCountry] = useState('');
    const dispatch = useDispatch()
    const { translate, lang } = useSelector(state => state.general);
    const { toFollowEstablishment, accountData } = useSelector(state => state.account);
    const [loginType, setLoginType] = useState('login');
    const [error, setError] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');

    const [zoomIn, setZoomIn] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const [viewPassword1, setViewPassword1] = useState(false);
    const [viewPassword2, setViewPassword2] = useState(false);
    const [viewPassword3, setViewPassword3] = useState(false);
    const [viewPassword4, setViewPassword4] = useState(false);
    const [viewPassword5, setViewPassword5] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);


    const [userType, setUserType] = useState('consumer');
    const [accountInformation, setAccountInformation] = useState(false);
    // const [preferred_language, setPreferred_language] = useState(lang === 'nl' ? { id: 'nl', name: 'Nederlands' } : { id: 'fr', name: 'Français' });

    // login states
    const [LoginEmailUsername, setLoginEmailUsername] = useState('');
    const [LoginPassword, setLoginPassword] = useState('');

    // register states
    // consumer
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerRepeatPassword, setRegisterRepeatPassword] = useState('');
    // business
    const [registerEstablishmentNumber, setRegisterEstablishmentNumber] = useState('');
    const [registerEnterpriseNumber, setRegisterEnterpriseNumber] = useState('');
    const [registerOtp, setRegisterOtp] = useState('');
    const [seePassword, setSeePassword] = useState(false);

    // extra info register
    const [registerUsername, setRegisterUsername] = useState('');
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerDateOfBirth, setRegisterDateOfBirth] = useState('');
    const [registerTelephone, setRegisterTelephone] = useState('');
    const [registerCountry, setRegisterCountry] = useState('');

    const [registerMunicipality, setRegisterMunicipality] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // Add this state at the top of your component

    
    const preferredLanguageOptions = [
        { id: 'nl', value: 'nl', label: 'Nederlands', },
        { id: 'fr', value: 'fr', label: 'Français' },
    ];

    const [preferred_language, setPreferred_language] = useState(preferredLanguageOptions[0]); // Defaults to the first option

    const handleLanguageChange = (selected) => {
        setPreferred_language(selected); // Ensure it directly updates with the selected option
    };

    const resetValues = () => {
        setRegisterEmail('')
        setRegisterPassword('')
        setRegisterRepeatPassword('')
        setRegisterEstablishmentNumber('')
        setRegisterEnterpriseNumber('')
        setRegisterOtp('')
    }

    const isUpper = (str) => {
        return /[A-Z]/.test(str);
    }
    const hasNumber = (str) => {
        return /[0-9]/.test(str);
    }

    const registerHandler = () => {
        if (userType === 'consumer') {
            if (registerEmail === '') { props.createNotification("warning", translate('please_enter_an_email')); setError('email') }
            else if (registerEmail.split('@').length > 2) { props.createNotification("warning", translate('more_than_one_at')); setError('email') }
            else if (!/\S+@\S+\.\S+/.test(registerEmail)) { props.createNotification("warning", translate('not_a_valid_email')); setError('email') }
            else if (registerPassword === "") { props.createNotification("warning", translate('please_enter_a_password')); setError('password') }
            else if (registerPassword.length < 8) { props.createNotification("warning", translate('password_8_characters')); setError('password') }
            else if (!isUpper(registerPassword)) { props.createNotification("warning", translate('password_1_capital')); setError('password') }
            else if (!hasNumber(registerPassword)) { props.createNotification("warning", translate('password_1_number')); setError('password') }
            else if (registerRepeatPassword === "") { props.createNotification("warning", translate('enter_password_repeat')); setError('repeat_password') }
            else if (registerPassword !== registerRepeatPassword) { props.createNotification("warning", translate('passwords_do_not_match')); setError('repeat_password') }
            else {
                axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/check/email/`, {
                    email: registerEmail,
                }).then((res) => {
                    if (res.data.status === 'Ok') { setAccountInformation(true) }
                    else { props.createNotification("warning", translate('email_already_in_use')); setError('email') }
                })
            }
        }
        else {
            if (registerEmail === '') { props.createNotification("warning", translate('please_enter_an_email')); setError('email') }
            else if (!/\S+@\S+\.\S+/.test(registerEmail)) { props.createNotification("warning", translate('not_a_valid_email')); setError('email') }
            else if (registerPassword === "") { props.createNotification("warning", translate('please_enter_a_password')); setError('password') }
            else if (registerPassword.length < 8) { props.createNotification("warning", translate('password_8_characters')); setError('password') }
            else if (!isUpper(registerPassword)) { props.createNotification("warning", translate('password_1_capital')); setError('password') }
            else if (!hasNumber(registerPassword)) { props.createNotification("warning", translate('password_1_number')); setError('password') }
            else if (registerRepeatPassword === "") { props.createNotification("warning", translate('enter_password_repeat')); setError('repeat_password') }
            else if (registerPassword !== registerRepeatPassword) { props.createNotification("warning", translate('passwords_do_not_match')); setError('repeat_password') }
            else if (registerEnterpriseNumber === "") { props.createNotification("warning", translate('please_enter_enterprise_number')); setError('enterprise') }
            else if (registerEstablishmentNumber === "") { props.createNotification("warning", translate('please_enter_establishment_number')); }
            else if (municipalityFollowing.length === 0) { props.createNotification("warning", translate('please_select_at_least_one_municipality')); setError('establishment'); }
            else {
                // controle op OTP en establishments + enterprise
                axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/check/email/`, {
                    email: registerEmail,
                }).then((res) => {
                    if (res.data.status === 'Ok') { setAccountInformation(true) }
                    else { props.createNotification("warning", translate('email_already_in_use')); setError('email') }

                })
            }
        }
    }

    // const extraRegisterHandler = () => {
    //     if (registerUsername === '') { props.createNotification("warning", translate('please_enter_username')); setError('username') }
    //     else if (registerFirstName === '') { props.createNotification("warning", translate('please_enter_first_name')); setError('prename') }
    //     else if (registerLastName === '') { props.createNotification("warning", translate('please_enter_last_name')); setError('name') }
    //     else {
    //         axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/check/username/`, {
    //             username: registerUsername,
    //         }).then((res) => {
    //             if (res.data.status === 'Ok') {
    //                 axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/confirm/?site=stadgeemente"`, {
    //                     registerEmail: registerEmail,
    //                     registerPassword: registerPassword,
    //                     registerRepeatPassword: registerRepeatPassword,
    //                     registerEstablishmentNumber: registerEstablishmentNumber,
    //                     registerEnterpriseNumber: registerEnterpriseNumber,
    //                     registerOtp: registerOtp,
    //                     registerUsername: registerUsername,
    //                     registerFirstName: registerFirstName,
    //                     registerLastName: registerLastName,
    //                     registerDateOfBirth: registerDateOfBirth,
    //                     registerTelephone: registerTelephone,
    //                     preferred_language: preferred_language,
    //                     userType: userType
    //                 }).then(() => {
    //                     props.createNotification("info", translate('please_confirm_account'))
    //                     setTimeout(() => {
    //                         dispatch(toggleLoginScreen(false))
    //                         dispatch(toFollowEst(""))
    //                     }, 2000);
    //                 })
    //             }
    //             else { props.createNotification("warning", translate('username_already_in_use')); setError('username') }
    //         })


    //     }
    // }
    const extraRegisterHandler = () => {
        setIsRegistering(true);
        if (registerFirstName === '') { props.createNotification("warning", translate('please_enter_first_name')); setError('prename'); setIsRegistering(false); }
        else if (registerLastName === '') { props.createNotification("warning", translate('please_enter_last_name')); setError('name'); setIsRegistering(false); }
        else if (notFromBelgium && registerCountry === '') { props.createNotification("warning", translate('please_select_country')); setError('country'); setIsRegistering(false); }
        else if (!notFromBelgium && registerMunicipality === '') { props.createNotification("warning", translate('please_select_municipality')); setError('municipality'); setIsRegistering(false); }
        else if (municipalityFollowing.length === 0 && provinceFollowing.length === 0) { props.createNotification("warning", translate('please_select_at_least_one_municipality_to_follow')); setError('following_municipalities'); setIsRegistering(false); }


        else {
            axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/check/email/`, {
                email: registerEmail,
            }).then((res) => {
                if (res.data.status === 'Ok') {
                    // Proceed with registration
                    axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/register/`, {
                        registerEmail: registerEmail,
                        registerPassword: registerPassword,
                        registerRepeatPassword: registerRepeatPassword,
                        registerEstablishmentNumber: registerEstablishmentNumber,
                        registerEnterpriseNumber: registerEnterpriseNumber,
                        registerFirstName: registerFirstName,
                        registerLastName: registerLastName,
                        registerTelephone: registerTelephone,
                        preferred_language: preferred_language,
                        registerNotFromBelgium: notFromBelgium,
                        registerMunicipality: municipality,
                        registerProvince: provinceFollowing,
                        registerCountry: registerCountry,
                        userType: userType,
                        followingMunicipalities: municipalityFollowing,
                    }).then(() => {
                        props.createNotification("success", translate('registration_successful'));

                        // Automatically log in the user
                        axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/login/`, {
                            emailUsername: registerEmail,
                            password: registerPassword,
                        }).then((loginRes) => {
                            localStorage.setItem("StadGemeenteToken", loginRes.data.token);
                            dispatch(toggleLoginScreen(false));
                            dispatch(toFollowEst(""));
                            dispatch({ type: "TOGGLE_LOGGED_IN_STATE", value: true });
                            // window.location.reload(true); // Refresh the page to reflect login state
                            dispatch({ type: "TOGGLE_FOLLOW_STATE", value: true });
                            window.location.href = "/volgende";
                        }).catch((err) => {
                            console.error("Login after registration failed:", err);
                            props.createNotification("error", translate('login_failed'));
                        }).finally(() => {
                            setIsRegistering(false); // Stop loader
                        });
                    }).catch((err) => {
                        props.createNotification("error", translate('registration_failed'));
                        console.error(err);
                        setIsRegistering(false);
                    });
                } else {
                    props.createNotification("warning", translate('username_already_in_use'));
                    setError('username');
                    setIsRegistering(false);
                }
            }).catch((err) => {
                props.createNotification("error", translate('username_check_failed'));
                console.error(err);
                setIsRegistering(false);
            });
        }
    };



    const followHandler = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("StadGemeenteToken")}`,
        };
        axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/follow_establishment/${toFollowEstablishment}/?lang=${lang}`, {
            headers: headers,
            token: localStorage.getItem("StadGemeenteToken"),
            following: true
        }).then(() => {
        }).catch((err) => {
            if (err.response.data.Status === 'Duplicate group') {
                // dispatch(toggleOpenFollowingConflict(err.response.data.establishment))
            }
        })

    }



    const loginHandler = () => {
        axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/login/`, {
            emailUsername: LoginEmailUsername,
            password: LoginPassword,
        })
            .then((res) => {
                localStorage.setItem("StadGemeenteToken", res.data.token);
                followHandler()
                dispatch({ type: "TOGGLE_LOGGED_IN_STATE", value: true });
                dispatch({ type: "TOGGLE_FOLLOW_STATE", value: true });
                setTimeout(() => {
                    window.location.href = "/volgende";
                }, 500);
                dispatch({ type: "TOGGLE_FOLLOW_STATE", value: true });

            })
            .catch((error) => {
                console.error(error)
                setError('login')
            })
    }


    const forgotHandler = () => {
        if (forgotEmail === "") { props.createNotification("warning", translate('please_enter_an_email')); setError('repeat_password') }
        else {
            axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/stadgemeente/forgot/?site=stad-gemeente`, {
                emailUsername: forgotEmail,
            })
                .then((res) => {
                    props.createNotification("info", translate('check_mail_password_reset'))
                })
                .catch((error) => {
                    props.createNotification("warning", "Opgegeven email bestaat niet");
                })
        }
    }

    // when pressing escape, this will close the login screen
    const keyDownHandler = (e) => {
        if (e.keyCode === 27) {
            dispatch(toggleLoginScreen(false))
            dispatch(toFollowEst(""))

        }
        // if (e.keyCode === 13) { loginHandler() }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);
    })

    useEffect(() => {
        // Fetch municipalities when the component mounts
        const fetchMunicipalities = async () => {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86`,
            };
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/geo/municipality/?fields=id,name_nl,name_fr,name_nl,name_fr,name_de,name_en&paginate=false&ordering=name_nl,name_fr,name_de,name_en`,
                    { headers }
                );
                if (response.data) {
                    // Map response to format required by OsnSelect
                    const municipalitiesOptions = response.data.map((item) => {
                        var name =
                            (item.name_nl && item.name_fr && `${item.name_nl} / ${item.name_fr}`) ||
                            item.name_nl ||
                            item.name_fr ||
                            item.name_de ||
                            item.name_en ||
                            "Unknown"; // Fallback for missing data
                            if (name.toLowerCase().startsWith("'s")) {
                                name = name.replace(/^'s\s*/i, ''); // Remove leading `'s`
                            } 

                        return {
                            id: item.id, // Assuming the name is unique; replace with actual ID if available
                            name: name,
                        };

                    })
                    municipalitiesOptions.sort((a, b) => a.name.localeCompare(b.name));
    
                    setMunicipalities(municipalitiesOptions);
                }
            } catch (error) {
                console.error("Error fetching municipalities:", error);
            }

            

            // Fetch provinces
            const fetchProvinces = async () => {
                const headers = {
                    "Content-Type": "application/json",
                    Authorization: `Token ae0e4f2df7b0d54ce458ca65ca53849e92691e86`,
                };
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/geo/province/?fields=id,name&paginate=false&ordering=name`,
                        { headers }
                    );
                    if (response.data) {
                        const provinceOptions = response.data.map((item) => ({
                            id: item.id,
                            name: item.name,
                        }));

                        setProvinces(provinceOptions);
                    }
                } catch (error) {
                    console.error("Error fetching provinces:", error);
                }
            };

            fetchProvinces();
        };

        fetchMunicipalities();
    }, []);

    const filteredMunicipalities = municipalities
    .filter((m) =>
      m.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const searchLower = searchTerm.toLowerCase();
      const aIndex = a.name.toLowerCase().indexOf(searchLower);
      const bIndex = b.name.toLowerCase().indexOf(searchLower);
      if (aIndex === bIndex) {
        return a.name.localeCompare(b.name);
      }
      return aIndex - bIndex;
    });
  
    
    const optionsForMunicipality = filteredMunicipalities.map((m) => ({
        value: m.id,
        label: m.name,
    }));
    const optionsForFollowingMunicipalities = municipalities.map((m) => ({
        value: m.id,
        label: m.name,
    }));
    const optionsForFollowingProvinces = provinces.map((p) => ({
        value: p.id,
        label: p.name,
    }));

    const handleFollowingProvinceChange = (selected) => {
        setProvinceFollowing(selected); // Updating state for provinces
    }; 

    // Find the currently selected option
    const selectedOption = optionsForMunicipality.find((option) => option.value === municipality) || null;

    // const handleChange = (selected) => {
    //     // Set the selected municipality ID
    //     setMunicipality(selected ? selected.value : null);
    //     setRegisterMunicipality(selected ? selected.value : null);
    // };

    const handleChange = (selected) => {
        setMunicipality(selected ? selected.value : null);
        setRegisterMunicipality(selected ? selected.value : null);
        setSearchTerm(''); // Clear the search term after selecting a municipality
    };
    const handleFollowingMunicipalityChange = (selected) => {
        setMunicipalityFollowing(selected); // Update state
    };

    return (
        <div id="login_wrapper">
            <link rel="stylesheet" href="//use.fontawesome.com/releases/v5.6.3/css/all.css"></link>
            <div className="login_content">
                <Icon className={`close_icon ${zoomIn ? 'zoom' : ''}`} name="Close" onClick={() => { dispatch(toggleLoginScreen(false)); dispatch(toFollowEst("")) }} />
                <figure>
                    {lang === 'nl' ?
                        <img src={require("../assets/imgs/logos/mijn.stad-gemeente_complete.svg").default} alt=" logo stadgemeente" />
                        :
                        <img src={require("../assets/imgs/logos/ma.ville-commune_complete.svg").default} alt=" logo commune" />
                    }
                </figure>
                {/* {accountInformation ?
                    <h2>bedankt om <span style={{ "color": "var(--pn)" }}>&nbsp;jouw account&nbsp;</span>te bevestigen!</h2>
                    : */}
                {lang === 'nl' ?
                    <h2>Welkom op <span style={{ "color": "#1693D4" }}>&nbsp;Mijn Stad Gemeente</span></h2>
                    :
                    <h2>BIENVENUE DANS MA VILLE <span style={{ "color": "#1693D4" }}>&nbsp;COMMUNE</span></h2>
                }
                {/* } */}
                {
                    accountInformation ?
                        <div className="login_extra_info">
                            {lang === 'nl' ?
                                <h2>Vul hieronder nog wat <span style={{ "color": "#1693D4" }}>&nbsp;gegevens&nbsp;</span>van je aan</h2>
                                :
                                <h2>Remplissez certaines <span style={{ "color": "#1693D4" }}>&nbsp;de vos coordonnées&nbsp;</span>ci-dessous</h2>
                            }

                            <div className="login_extra_info_fields" >
                                {/*<OsnInputText error={error === 'username' ? true : false} className="username half" icon="Entrepreneur" title={`${translate('username')}*`} onChange={(e) => { setRegisterUsername(e.target.value); setError('') }} />*/}
                                <OsnInputText error={error === 'prename' ? true : false} className="firstname half" icon="Info" title={`${translate('ci_prename')}*`} onChange={(e) => { setRegisterFirstName(e.target.value); setError('') }} />
                                <OsnInputText error={error === 'name' ? true : false} className="lastname half" icon="Info" title={`${translate('ci_lastname')}*`} onChange={(e) => { setRegisterLastName(e.target.value); setError('') }} />
                                <div className="language-container">
                                    <label className="pref_language">{translate('preferred_language')}</label>
                                    <Select
                                        className="lang-select"
                                        value={preferred_language}
                                        onChange={handleLanguageChange}
                                        options={preferredLanguageOptions}
                                        components={{
                                            DropdownIndicator: (props) => (
                                                <components.DropdownIndicator {...props}>
                                                    <span style={{ fontSize: "12px", transform: "rotate(180deg)" }}>▲</span> {/* Simple upward arrow */}
                                                </components.DropdownIndicator>
                                            ),
                                        }}
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                width: "16rem", // Set a fixed width
                                            }),
                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                padding: "8px", // Adjust padding for better alignment
                                            }),
                                        }}
                                    />
                                </div>

                                <OsnInputText className="telephone half" icon="Phone" title={translate('phone')} maxLength={14} onChange={(e) => { setRegisterTelephone(e.target.value); setError('') }} />

                            </div>
                            <div className="not-from-belgium">
                                <input
                                    type="checkbox"
                                    id="notFromBelgium"
                                    checked={notFromBelgium}
                                    onChange={(e) => setNotFromBelgium(e.target.checked)}
                                />
                                <label htmlFor="notFromBelgium">{translate('not_from_belgium')}</label>
                            </div>
                            {!notFromBelgium && (
                                // <div className="municipality-container">
                                //     <label className="municipality-label">{translate('municipality')}</label>
                                //     {/* <div className="municipality-select-wrapper"> */}
                                //     <OsnSelect
                                //         className="municipality-select"
                                //         onChange={(e) => {
                                //             const selectedMunicipality = municipalities.find((m) => m.name === e.label);
                                //             setMunicipality(selectedMunicipality?.id || null);
                                //         }}
                                //         active={municipalities.find((m) => m.id === municipality)?.name || ''}
                                //         optionsForMunicipality={municipalities.map((m) => ({ label: m.name }))} // Only show the name in the dropdown
                                //         hidden={notFromBelgium}
                                //     />

                                //     {/* </div>+ */}
                                // </div>

                                <div className="municipality-select-wrapper">
                                    <Select
                                        className="municipality-select"
                                        value={selectedOption}
                                        onInputChange={(value) => setSearchTerm(value)}
                                        onChange={handleChange}
                                        options={filteredMunicipalities.map((m) => ({
                                            value: m.id,
                                            label: m.name,
                                        }))} 
                                        placeholder={translate('search_municipality_StadGemeente')}
                                        isDisabled={notFromBelgium} // Disable the select if notFromBelgium is true
                                        noOptionsMessage={() => "No municipalities found"} // Message when no matches
                                        components={{
                                            DropdownIndicator: (props) => (
                                                <components.DropdownIndicator {...props}>
                                                    <span style={{ fontSize: "12px", transform: "rotate(180deg)" }}>▲</span> {/* Simple upward arrow */}
                                                </components.DropdownIndicator>
                                            ),
                                        }}
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                width: "225px", // Set a fixed width
                                            }),
                                            dropdownIndicator: (provided) => ({
                                                ...provided,
                                                padding: "8px", // Adjust padding for better alignment
                                            }),
                                        }}

                                    />
                                </div>
                            )}

                            {notFromBelgium && (
                                <div className="country-container">
                                    <OsnInputText
                                        className="country-input"
                                        icon="Belgium"
                                        title={`${translate('country')}*`}
                                        onChange={(e) => setRegisterCountry(e.target.value)}
                                    />

                                </div>
                            )}
                            <div className="following-municipality-select-wrapper">
                                <div className="cities-label-container">
                                    <label className="municipality-label">{translate('which_cities_and_or_municipalities_would_you_like_to_follow')}</label>
                                </div>
                                <br />
                                <br />
                                {/* <DualListBox
                                    options={optionsForFollowingMunicipalities} // Pass the options array with value-label pairs
                                    onChange={(selected) => {
                                        // Explicit event handling for Safari
                                        const event = window.event || {};
                                        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
                                        if (isMac && event.type === 'mousedown') {
                                            event.preventDefault(); // Prevent Safari's default behavior
                                        }
                                        handleFollowingMunicipalityChange(selected);
                                    }}
                                    selected={municipalityFollowing}
                                    canFilter // Enable search filtering
                                    filterPlaceholder={translate('search_municipality')} // Add translation for filter placeholder

                                /> */}
                                <CustomDualListBox options={optionsForFollowingMunicipalities} selected={municipalityFollowing} 
                                onChange={handleFollowingMunicipalityChange} />
                            <label style={{ marginTop: "2px" }}  className="multi_select_help_text">{translate('to_multi_select_the_municipality_using_control_key')}</label> 

                            </div>

                            <div className="following-municipality-select-wrapper">
                                <div className="cities-label-container">
                                    <label className="municipality-label">
                                        {translate('which_provinces_would_you_like_to_follow')}
                                    </label>
                                </div>
                                <br />
                                <br />
                                {/* <DualListBox
                                    options={optionsForFollowingProvinces} // Pass provinces instead of municipalities
                                    onChange={(selected) => {
                                        const event = window.event || {};
                                        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
                                        if (isMac && event.type === 'mousedown') {
                                            event.preventDefault();
                                        }
                                        handleFollowingProvinceChange(selected);
                                    }}
                                    selected={provinceFollowing} // Now represents selected provinces
                                    canFilter
                                    filterPlaceholder={translate('search_province')}
                                /> */}
                                <CustomDualListBox options={optionsForFollowingProvinces} selected={provinceFollowing} onChange={handleFollowingProvinceChange} />
                            </div>


                            <label className="multi_select_help_text">{translate('to_multi_select_the_municipality_using_control_key')}</label>

                            <div className="login_register_button">
                                {isRegistering ? (
                                    <div className="loader"></div> // Circular loader element
                                ) : (
                                    <div onClick={() => extraRegisterHandler()} className="login_button">
                                        {translate('register')}
                                    </div>
                                )}
                            </div>


                        </div>
                        :

                        <div className="innerLogin">
                            {loginType === 'login' ?
                                forgotPassword ?
                                    <div className="login">
                                        <OsnInputText className="email" icon="Mail" title={translate('email')} onKeyDown={(e) => e.keyCode === 13 && loginHandler()} onChange={(e) => setForgotEmail(e.target.value)} />
                                        <span className="forgot_password" onClick={() => setForgotPassword(false)} >{translate('password_remember')}</span>
                                        <div className="login_button" onClick={() => forgotHandler()}>{translate('send')}</div>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <div className="slider-container">
                                            {lang === 'nl' ? (
                                                <Slider {...sliderSettings}>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-1.svg' alt="i1" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-2.svg' alt="i2" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-3.svg' alt="i3" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-4.svg' alt="i4" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-5.svg' alt="i5" /></div>
                                                </Slider>
                                            ) : (
                                                <Slider {...sliderSettings}>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-1.svg' alt="i1" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-2.svg' alt="i2" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-3.svg' alt="i3" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-4.svg' alt="i4" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-5.svg' alt="i5" /></div>
                                                </Slider>
                                            )}

                                        </div>
                                    </div>
                                    :

                                    <div className="login">
                                        <OsnInputText value={LoginEmailUsername} error={error === 'login' ? true : false} className="email" icon="Mail" title={translate('email_or_username')} onKeyDown={(e) => e.keyCode === 13 && loginHandler()} onChange={(e) => { setLoginEmailUsername(e.target.value); setError('') }} /><br />
                                        <div className="input-container">
                                            <OsnInputText 
                                                error={error === 'login' ? true : false} 
                                                className="password" 
                                                cta_icon={viewPassword ? "Hidden" : "Visible"} 
                                                cta_click={() => setViewPassword(!viewPassword)} 
                                                icon="Locked" 
                                                title={translate('password')} 
                                                type={viewPassword ? "text" : "password"} 
                                                onKeyDown={(e) => e.keyCode === 13 && loginHandler()} 
                                                onChange={(e) => { setLoginPassword(e.target.value); setError(''); }} 
                                            />
                                            <span id="passwordIcon"> 
                                                <Icon name={viewPassword ? "PasswordShow" : "PasswordHide"} onClick={() => setViewPassword(!viewPassword)} />
                                            </span>
                                        </div>


                                        <span className="forgot_password" onClick={() => setForgotPassword(true)} >{translate('forgot_password')}</span>
                                        <span className="error_message">{error === 'login' && translate('wrong_login_data')}</span>
                                        <div className="login_button" onClick={() => loginHandler()}>{translate('log_in')}</div>
                                        <span className="already_account" onClick={() => { setLoginType("register"); setRegisterEmail(''); setError(''); }}>{translate('i_dont_have_account')}</span>
                                        <div className="slider-container">
                                            {lang === 'nl' ? (
                                                <Slider {...sliderSettings}>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-1.svg' alt="i1" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-2.svg' alt="i2" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-3.svg' alt="i3" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-4.svg' alt="i4" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-5.svg' alt="i5" /></div>
                                                </Slider>
                                            ) : (
                                                <Slider {...sliderSettings}>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-1.svg' alt="i1" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-2.svg' alt="i2" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-3.svg' alt="i3" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-4.svg' alt="i4" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-5.svg' alt="i5" /></div>
                                                </Slider>
                                            )}

                                        </div>

                                    </div>
                                :

                                <div className="login_register">
                                    {/* <div className="login_type_buttons">
                                        <div onClick={() => { resetValues(); setUserType('consumer') }} className={`type_button ${userType === "consumer" ? 'active' : ''}`}>{translate('i_am_consumer')}</div>
                                        <div onClick={() => { resetValues(); setUserType('business') }} className={`type_button ${userType === "business" ? 'active' : ''}`}>{translate('i_am_entrepreneur')}</div>
                                    </div> */}

                                    {/* {userType === 'consumer' ? */}
                                    <React.Fragment>
                                    <OsnInputText value={registerEmail} error={error === 'email'} className="email" icon="Mail" title={translate('email')} onChange={(e) => { setRegisterEmail(e.target.value); setError('') }} />

                                        <div className="input-container">
                                        <span className="password-icon" onClick={() => setViewPassword1(!viewPassword1)}>
                                        <Icon name={viewPassword1 ? "PasswordShow" : "PasswordHide"} />
                                        </span>
                                        <OsnInputText error={error === 'password'} cta_icon={viewPassword1 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword1(!viewPassword1)} className="email" icon="Locked" title={translate('password')} type={viewPassword1 ? "text" : "password"} onChange={(e) => { setRegisterPassword(e.target.value); setError('') }} />
                                        </div>

                                        <div className="input-container">
                                        <span className="password-icon" onClick={() => setViewPassword2(!viewPassword2)}>
                                        <Icon name={viewPassword2 ? "PasswordShow" : "PasswordHide"} />
                                        </span>
                                        <OsnInputText error={error === 'repeat_password'} cta_icon={viewPassword2 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword2(!viewPassword2)} className="email" icon="Locked" title={translate('password_repeat')} type={viewPassword2 ? "text" : "password"} onChange={(e) => { setRegisterRepeatPassword(e.target.value); setError('') }} />
                                        </div>


                                        <div onClick={() => registerHandler()} className="login_button_register">{translate('next')}</div>
                                        <span className="already_account" onClick={() => setLoginType("login")}>{translate('i_already_have_account')}</span>
                                        <div className="slider-container">
                                            {lang === 'nl' ? (
                                                <Slider {...sliderSettings}>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-1.svg' alt="i1" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-2.svg' alt="i2" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-3.svg' alt="i3" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-4.svg' alt="i4" /></div>
                                                    <div><img src='/SliderImages/Plenny__mijn.stad-gemeente-5.svg' alt="i5" /></div>
                                                </Slider>
                                            ) : (
                                                <Slider {...sliderSettings}>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-1.svg' alt="i1" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-2.svg' alt="i2" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-3.svg' alt="i3" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-4.svg' alt="i4" /></div>
                                                    <div><img src='/SliderImages/Plenny_ma.ville-commune-5.svg' alt="i5" /></div>
                                                </Slider>
                                            )}

                                        </div>
                                    </React.Fragment>
                                    {/* :
                                        <React.Fragment>
                                            <OsnInputText error={error === 'email' ? true : false} className="email" icon="Mail" title={translate('email')} onChange={(e) => { setRegisterEmail(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'password' ? true : false} cta_icon={viewPassword3 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword3(!viewPassword3)} className="password" icon="Locked" title={translate('password')} type={viewPassword3 ? "text" : "password"} onChange={(e) => { setRegisterPassword(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'repeat_password' ? true : false} cta_icon={viewPassword4 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword4(!viewPassword4)} className="password" icon="Locked" title={translate('password_repeat')} type={viewPassword4 ? "text" : "password"} onChange={(e) => { setRegisterRepeatPassword(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'enterprise' ? true : false} className="Business" icon="Entrepreneur" title={translate('your_enterprise_number')} onChange={(e) => { setRegisterEnterpriseNumber(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'establishment' ? true : false} className="password" icon="Groups" title={translate('your_establishment_number')} onChange={(e) => { setRegisterEstablishmentNumber(e.target.value); setError('') }} />
                                            <OsnInputText error={error === 'otp' ? true : false} cta_icon={viewPassword5 ? 'Hidden' : "Visible"} cta_click={() => setViewPassword5(!viewPassword5)} className="password" icon="Locked" title={translate('otp')} type={viewPassword5 ? "text" : "password"} onChange={(e) => { setRegisterOtp(e.target.value); setError('') }} />
                                            <span className="forgot_password">{translate('dont_have_otp_yet')}</span>
                                            <div onClick={() => registerHandler()} className="login_button">{translate('next')}</div>
                                            <span className="already_account" onClick={() => setLoginType("login")}>{translate('i_already_have_account')}</span>
                                        </React.Fragment> 
                                     */}
                                </div>

                            }
                        </div>
                }
                {/* {loginType === 'login' &&
                    <div className="cread">
                        <p>{translate('logged_in_users_follow_their')} <span style={{ "color": "var(--pn)" }}>{translate('favorite_entrepreneurs')}</span> {translate('and_have_access_to')}</p>
                        <div className="cread-container" style={{ display: "flex", padding: "7px 25px", gap: "5px" }}>
                            <figure className={`${zoomIn ? 'zoom' : ''}`}>
                                <a target="_blank" href={require(`../assets/imgs/cread-gebruikers_${lang}.png`)}>
                                    <img src={require(`../assets/imgs/cread-gebruikers_${lang}.png`)} />
                                    <Icon name="Search" />
                                </a>
                            </figure>
                            <ul>
                                <li>{translate('promotions_that_will_be_offered_in_the_future')}</li>
                                <li>{translate('exceptional_offers_especially_for_loyal_customers')}</li>
                                <li>{translate('the_ability_to_create_smart_shopping_lists')} </li>
                            </ul>
                        </div>
                    </div>} */}

            </div>
            <div className="login_bg" onClick={() => dispatch(toggleLoginScreen(false))}></div>
        </div>
    );
}

export default Login;
